import React from "react";
import { useNavigate } from "react-router-dom";
export default function ForgetPassword() {
  const navigate = useNavigate();
  async function handleSubmit(event) {
    event.preventDefault();

    const url = `${process.env.REACT_APP_BAC_URL}/auth/resetuserpassword`;
    const userInfo = {
      email: event.target.elements.email.value,
    };
    const data = JSON.stringify(userInfo);

    const response = await fetch(url, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: data,
    });

    if (response.status === 200) {
      navigate("/succesforgetpassword");
    } else {
      alert("Une erreur est survenue, veuillez réessayer ultérieurement");
    }
  }
  return (
    <>
      <h3 className="contact-title">Reinitialisation mot de passe</h3>
      <form onSubmit={handleSubmit} className="signin-form">
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <p htmlFor="email">
            Rentrez votre email dans le champ ci-dessous puis cliquez sur
            envoyer pour recevoir un email de réinitialisation du mot de passe.{" "}
          </p>
        </div>
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <label htmlFor="email">Email</label>
          <input
            maxLength="40"
            type="text"
            autoComplete="off"
            id="email"
            placeholder="exemple@gmail.com"
          />
        </div>

        <input type="submit" value="Envoyer" />
      </form>
    </>
  );
}
