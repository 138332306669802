import React from "react";
import { Link } from "react-router-dom";
import copyright from "../images/copyright.png";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopyright, faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { useContext } from "react";
import { userContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
function Footer() {
  const { setEmail, setRole, role } = useContext(userContext);
  const navigate = useNavigate();
  async function logOut() {
    const url = `${process.env.REACT_APP_BAC_URL}/auth/logout`;
    const token = localStorage.getItem("XSRF-TOKEN");
    const options = {
      method: "POST",
      headers: { Authorization: "bearer " + token },
      credentials: "include",
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      localStorage.removeItem("XSRF-TOKEN");
      setEmail("");
      setRole("");

      navigate("/connexion");
    } else {
      alert("Une erreure est survenue");
    }
  }
  return (
    <footer>
      <Link
        to="/cgu"
        style={{
          textDecoration: "underline",
          color: "#F0870A",
          marginRight: 5,
        }}
      >
        CGU
      </Link>
      <FontAwesomeIcon className="copyright" icon={faCopyright} />
      <h5>2022/2023 Sébastien Robello</h5>
      {!role ? (
        <></>
      ) : (
        <div>
          <FontAwesomeIcon
            size="lg"
            icon={faSignOutAlt}
            className="btn-logout-footer"
            onClick={() => logOut()}
          />
        </div>
      )}
    </footer>
  );
}

export default Footer;
