import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faHome,
  faUserCircle,
  faContactBook,
  faEnvelope,
  faUserPlus,
  faIdCard,
  faFileSignature,
  faSignOutAlt,
  faCalendarAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Nav, NavItem } from "reactstrap";
import { NavLink } from "react-router-dom";
import { useContext } from "react";
import { userContext } from "../context/userContext";
import { useNavigate } from "react-router-dom";
const Navigation = (props) => {
  const { email, setEmail, role, setRole, profilPicture } =
    useContext(userContext);
  const navigate = useNavigate();
  async function logOut() {
    const url = `${process.env.REACT_APP_BAC_URL}/auth/logout`;
    const token = localStorage.getItem("XSRF-TOKEN");
    const options = {
      method: "POST",
      headers: { Authorization: "bearer " + token },
      credentials: "include",
    };
    const response = await fetch(url, options);

    if (response.status === 200) {
      localStorage.removeItem("XSRF-TOKEN");
      setEmail("");
      setRole("");

      navigate("/connexion");
    } else {
      alert("Une erreure est survenue");
    }
  }
  let tabs = [];
  if (!role) {
    tabs = [
      {
        route: "/",
        icon: faHome,
        label: "Home",
      },
      {
        route: "/contact",
        icon: faEnvelope,
        label: "Contact",
      },
      {
        route: "/connexion",
        icon: faUserCircle,
        label: "Connexion",
      },
      {
        route: "/Inscription",
        icon: faUserPlus,
        label: "Inscription",
      },
    ];
  } else if (role === "user" || role === "public") {
    tabs = [
      {
        route: "/setting",
        icon: faIdCard,
        label: "Profil",
      },
    ];
  } else if (role === "admin") {
    tabs = [
      {
        route: "/setting",
        icon: faIdCard,
        label: "Profil",
      },
      {
        route: "/adminUserG",
        icon: faIdCard,
        label: "Admin",
      },
    ];
  }
  return (
    <div className="navbar-container">
      {/* Top Bar*/}
      <nav
        className="navbar navbar-expand-md navbar-light sticky-top"
        role="navigation"
      >
        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            NFT-Warriors
          </a>

          <Nav className="ml-auto">
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                <NavLink
                  to={tab.route}
                  className="nav-link bottom-nav-link"
                  activeClassName="active"
                >
                  {tab.label}
                </NavLink>
              </NavItem>
            ))}
            {!role ? (
              <></>
            ) : (
              <div>
                <img
                  src={`${process.env.REACT_APP_BAC_URL}/profilPicture/${profilPicture}`}
                  alt={"defaultprofil"}
                  width="40px"
                  height="40px"
                  className="border rounded-circle"
                  id="navbar-profil-img"
                />

                <FontAwesomeIcon
                  size="lg"
                  icon={faSignOutAlt}
                  className="btn-logout"
                  onClick={() => logOut()}
                />
              </div>
            )}
          </Nav>
        </div>
      </nav>

      {/* Bottom Tab Navigator */}

      <div className="title-contenair">
        <a className="nav-title" href="/">
          NFT-Warriors
        </a>
        {!role ? (
          <></>
        ) : (
          <img
            src={`${process.env.REACT_APP_BAC_URL}/profilPicture/${profilPicture}`}
            alt={"defaultprofil"}
            width="40px"
            height="40px"
            className="border rounded-circle"
            id="navbar-mobile-profil-img"
          />
        )}
      </div>
      <nav
        className=" fixed-bottom navbar-light bottom-tab-nav"
        role="navigation"
      >
        <Nav className="w-100">
          <div className=" d-flex flex-row justify-content-around w-100">
            {tabs.map((tab, index) => (
              <NavItem key={`tab-${index}`}>
                <NavLink
                  to={tab.route}
                  className="nav-link bottom-nav-link"
                  activeClassName="active"
                  id="nav-link-bottom"
                >
                  <div className="row d-flex flex-column justify-content-center align-items-center">
                    <FontAwesomeIcon
                      size="lg"
                      icon={tab.icon}
                      className="icon-tab"
                    />
                  </div>
                </NavLink>
              </NavItem>
            ))}
            {!role ? (
              <></>
            ) : (
              <div>
                <FontAwesomeIcon
                  size="lg"
                  icon={faSignOutAlt}
                  className="btn-logout"
                  onClick={() => logOut()}
                />
              </div>
            )}
          </div>
        </Nav>
      </nav>
    </div>
  );
};

export default Navigation;
