import React from "react";
import { useNavigate } from "react-router-dom";

export default function ValidationSignUp() {
  const navigate = useNavigate();
  return (
    <>
      <h3 className="contact-title">Inscription</h3>
      <form className="signin-form">
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <p htmlFor="email">
            Inscription réussie, veuillez activer votre compte en cliquant sur
            le lien reçu dans votre boite mail
          </p>
        </div>

        <input
          type="submit"
          onClick={() => navigate("/connexion")}
          value="continuer"
        />
      </form>
    </>
  );
}
