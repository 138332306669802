import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import "./styles/index.scss";
import App from "./App";
import UserProvider from "./context/userContext";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-calendar/dist/Calendar.css";
///initialisation du rooter
const container = document.getElementById("root");
const root = createRoot(container);

root.render(
  //appelle d'user provider en prioritée pour
  //rendre le context accésible à toute l'application
  <UserProvider>
    <BrowserRouter>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </BrowserRouter>
  </UserProvider>
);
