import React from "react";
import { useNavigate } from "react-router-dom";

export default function ValidationSignIn() {
  const navigate = useNavigate();
  return (
    <>
      <h3 className="contact-title">connexion</h3>
      <form className="signin-form">
        <div style={{ paddingRight: 20, paddingLeft: 20 }}>
          <p htmlFor="email">Connexion réussie</p>
        </div>

        <input
          type="submit"
          onClick={() => navigate("/")}
          value="continuer"
          style={{ width: 150 }}
        />
      </form>
    </>
  );
}
