import { createContext, useState, useEffect } from "react";

export const userContext = createContext(
  { email: "", setEmail: () => {} },
  { role: "", setRole: () => {} },
  { descritpion: "", setdescription: () => {} },
  { profilPicture: "", setProfilPicture: () => {} },
  { name: "", setName: () => {} },
  { firstname: "", setFirstname: () => {} },
  { age: "", setAge: () => {} }
);

export default function UserProvider(props) {
  const [email, setEmail] = useState("");
  const [role, setRole] = useState("");
  const [age, setAge] = useState("");
  const [profilPicture, setProfilPicture] = useState("");
  const [description, setdescription] = useState("");
  const [name, setName] = useState("");
  const [firstname, setFirstname] = useState("");

  async function getUser() {
    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/auth/profil`;

    const options = {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
      },
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();

    if (response.status === 200) {
      setEmail(result.profil.email);
      setRole(result.profil.role);
      setProfilPicture(result.profil.profilPicture);
      setAge(result.profil.age);
      setName(result.profil.name);
      setFirstname(result.profil.firstname);
      setdescription(result.profil.description);
      console.log(result.profil);
    }
    if (response.status === 401) {
      refreshToken();
    }
  }

  async function refreshToken() {
    const url = `${process.env.REACT_APP_BAC_URL}/auth/refresh`;
    const options = {
      method: "POST",
      headers: {},
      credentials: "include",
    };
    const response = await fetch(url, options);

    let result = await response.json();

    if (response.status === 200) {
      localStorage.setItem("XSRF-TOKEN", result.xsrfToken);
      getUser();
    } else if (response.status === 401) {
      setEmail("");
      setRole("");
    }
  }
  useEffect(() => {
    getUser();
  }, []);

  return (
    <userContext.Provider
      value={{
        email,
        setEmail,
        role,
        setRole,
        profilPicture,
        setProfilPicture,
        getUser,
        refreshToken,
        name,
        firstname,
        age,
        description,
      }}
    >
      {props.children}{" "}
    </userContext.Provider>
  );
}
