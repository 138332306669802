import React from "react";
import { Routes, Route } from "react-router-dom";

import Cgu from "./components/cgu";
import "./styles/index.scss";
import NavBar from "./components/NavBar";
import Home from "./components/home";
import Contact from "./components/Contact";
import SignIn from "./components/SignIn";
import SignUp from "./components/SignUp";
import Footer from "./components/Footer";
import ValidationSignUp from "./components/ValidationSignUp";
import ValidationSignIn from "./components/ValidationSignIn";
import NotFound from "./components/NotFound";
import Settings from "./components/Settings";
import CheckSignUpToken from "./components/CheckSignUpToken";
import ForgetPassword from "./components/ForgetPassword";
import SuccesForgetPassword from "./components/SuccesForgetPassword";
import PasswordReset from "./components/PasswordReset";
import UsersGestion from "./components/UsersGestion";

function App() {
  return (
    <div>
      <NavBar />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/contact" element={<Contact />} />
        <Route path="/setting" element={<Settings />} />
        <Route path="/inscription" element={<SignUp />} />
        <Route path="/inscriptionreussi" element={<ValidationSignUp />} />
        <Route path="/connexion" element={<SignIn />} />
        <Route path="/connexionreussi" element={<ValidationSignIn />} />
        <Route path="/cgu" element={<Cgu />} />
        <Route path="/validate-email/*" element={<CheckSignUpToken />} />
        <Route path="/forgetpassword" element={<ForgetPassword />} />
        <Route path="/password-reset/*" element={<PasswordReset />} />
        <Route path="/adminUserG" element={<UsersGestion />} />
        <Route
          path="/succesforgetpassword"
          element={<SuccesForgetPassword />}
        />
        <Route path="*" element={<NotFound />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
