import React from "react";
import { useNavigate } from "react-router-dom";
function NotFound() {
  const navigate = useNavigate();
  return (
    <>
      <h3 className="contact-title">Vous vous étes perdu!!</h3>
      <input
        type="button"
        onClick={() => navigate("/")}
        value="retour a la page d'acceuil"
        style={{ display: "flex", margin: "0 auto", marginTop: 100 }}
      ></input>
    </>
  );
}

export default NotFound;
