import React, { useContext, useEffect, useState } from "react";
import { userContext } from "../context/userContext";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSearch,
  faHome,
  faUserCircle,
  faContactBook,
  faEnvelope,
  faUserPlus,
  faIdCard,
  faFileSignature,
  faSignOutAlt,
  faCalendarAlt,
  faTimesCircle,
  faCheckCircle,
  faPlusCircle,
  faMinusCircle,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import { Navigate, useNavigate } from "react-router";
import { Link } from "react-router-dom";
function PrivateProfil() {
  const {
    email,
    name,
    firstname,
    age,
    lvl,
    profilPicture,
    setProfilPicture,
    getUser,
    refreshToken,
  } = useContext(userContext);
  const [userImage, setUserImage] = useState([]);
  const navigate = useNavigate();

  const handleImage = (e) => {
    e.persist();

    const img = {
      preview: URL.createObjectURL(e.target.files[0]),
      data: e.target.files[0],
    };

    setUserImage(img);
  };

  async function handleSubmit(event) {
    event.preventDefault();

    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/auth/postprofil`;
    const userInfo = {
      name: event.target.elements.name.value,
      firstname: event.target.elements.firstname.value,
      age: event.target.elements.age.value,
    };

    const data = JSON.stringify(userInfo);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        "Content-Type": "application/json",
      },
      credentials: "include",
      body: data,
    });
    let result = await response.json();
    if (response.status === 200) {
      alert("Succès: Profil modifié");
      getUser();
    } else if (response.status === 401) {
      refreshToken();
    } else {
      alert("Une erreur est survenue, veuillez réessayer");
    }
  }

  async function handleSubmit2(event) {
    event.preventDefault();

    const token = localStorage.getItem("XSRF-TOKEN");
    const url = `${process.env.REACT_APP_BAC_URL}/auth/profilpicture/profilpicture`;
    const formData = new FormData();
    formData.append("file", userImage.data);
    formData.append("last", profilPicture);
    const response = await fetch(url, {
      method: "POST",
      headers: {
        Authorization: "bearer " + token,
        //"Content-Type": "multipart/form-data boundary=something",
      },
      credentials: "include",
      body: formData,
    });
    let result = await response.json();
    const res = JSON.stringify(result);

    if (response.status === 200) {
      console.log("image enregistrée");
      setProfilPicture(result.message);
    } else {
      console.log("une erreur est survenue");
    }
  }
  return (
    <div className="profil-container">
      <div className="private-image-container">
        <label id="label-img" htmlFor="img">
          Photo de profil
        </label>
        <form onSubmit={handleSubmit2}>
          <img
            src={`${process.env.REACT_APP_BAC_URL}/profilPicture/${profilPicture}`}
            alt={"defaultprofil"}
            width="200px"
            height="200px"
            className="border rounded-circle"
            id="private-profil-img"
          />
          <div className="input-img">
            <label className="" htmlFor="img">
              Entrez Nouvelle Image
            </label>
            <input
              type="file"
              name="uimage"
              onChange={handleImage}
              className="form-control"
              id="uploadBtn"
            />
          </div>
          <input type="submit" value="Enregistrer" className="btn-img" />
        </form>
      </div>

      <form onSubmit={handleSubmit} className="private-profil-container">
        <div className="private-container">
          <label htmlFor="email">Email</label>
          <input
            type="text"
            autoComplete="off"
            defaultValue={email}
            id="email"
            disabled
          />
        </div>

        <div className="private-container">
          <label htmlFor="name">Nom</label>
          <input type="text" autoComplete="off" defaultValue={name} id="name" />
        </div>

        <div className="private-container">
          <label htmlFor="firstName">Prénom</label>
          <input
            type="text"
            defaultValue={firstname}
            autoComplete="off"
            id="firstname"
          />
        </div>

        <div className="private-container">
          <label htmlFor="age">Age</label>
          <input type="text" autoComplete="off" defaultValue={age} id="age" />
        </div>

        <input type="submit" className="btn-validation" value="Modifier" />
      </form>
    </div>
  );
}

export default PrivateProfil;
