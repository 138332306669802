import istockphoto from "../images/istockphoto.jpg";
export default function Home() {
  return (
    <div className="home-container">
      <img className="bg-home" src={istockphoto}></img>
      <h3 className="home-text">
        Vos évaluation à portée de mains grace à Evaluator
      </h3>
    </div>
  );
}
